import React from "react";
import Image, { StaticImageData } from "next/image";

interface IntroPicContainerProps {
  imageSrc: StaticImageData;
  title: string;
  priority?: boolean;
}

function IntroPicContainer({
  imageSrc,
  title,
  priority = true,
}: IntroPicContainerProps) {
  return (
    <>
      <div className={`component-layout intro-pic-container`}>
        <Image
          src={imageSrc}
          title={title}
          alt={title}
          priority={priority}
          height={400}
          style={{
            objectFit: "cover",
          }}
        />
        <h1 className="title">{title}</h1>
      </div>
    </>
  );
}
export default IntroPicContainer;
